import React from "react";
import styled from "styled-components";
// Components
import PricingTable from "../Elements/PricingTable";
//Assets

import Aboutus from "../../assets/img/contact_us.jpg";
import { faDisplay } from "@fortawesome/free-solid-svg-icons";

export default function Pricing() {
  return (
    <Wrapper id="pricing">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">About Us</h1>
          </HeaderInfo>
          <div className="row" >
            <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8">
              <p className='font17' style={{textAlign:'justify'}}>
             <b> Mithra Consultancy </b> was established to offer value-added professional services, primarily in the corporate sector. Headquartered in Hyderabad, our aspirations extend globally. With promoters boasting over a decade of experience in Taxation, Financial Consultancy, and related domains, we are a team of committed professionals with diverse educational and professional backgrounds. 
             <br/><br/>
              Our mission is to cultivate enduring relationships founded on the quality of services rendered. Backed by a wealth of experience and expertise, we strive to deliver exceptional solutions to our clients. We envision a future where our global footprint expands, making a meaningful impact in various sectors. </p> 
            </div>
            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 flex">
            <img className="radius8" src={Aboutus} alt="Aboutus" style={{zIndex: 9,width:'100%'}} />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding: 50px 0;
`;
const HeaderInfo = styled.div`
  margin-bottom: 50px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;





