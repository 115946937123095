import React,{ useRef } from "react";
import emailjs from '@emailjs/browser';
import styled from "styled-components";
// Assets
import ContactImg1 from "../../assets/img/contact-1.png";
import ContactImg2 from "../../assets/img/contact-2.png";
import ContactImg3 from "../../assets/img/contact-3.png";

import ContactImg from "../../assets/img/contact_us_2_bg.png";


export default function Contact() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    console.log(e)

    let email_flag = false
    let ph_flag = false

    const form_values = e.target.elements

    console.log(typeof(form_values[2].value))

    for ( var i =0 ; i < form_values.length-1; i++){
      if (i!=2){
      if ((form_values[i].value) | (form_values[i].value !='' ) ) {
      email_flag=true
      }
    }
    }

    if ((form_values[2].value !='') & (email_flag==true)){
      email_flag = true
      
    }
    else if ((form_values[2].value =='') & (email_flag==false)){
      email_flag = false
      
    }
    else if ((form_values[2].value =='') & (email_flag==true)){
      email_flag = true
      
    }
    else {
      email_flag =false
      
    }

    console.log(form_values[2].value.length)
    if(((form_values[2]).value.length >=1) & ((form_values[2]).value.length <10)) {
      alert ("Enter the phone number properly");
      email_flag = false
    }

    else if (!form_values[1].value.includes('@')){
      alert ("Enter the Email properly");
      email_flag = false
    }



    if (email_flag) {
          emailjs
      .sendForm('service_ghz6cof', 'template_o4wmtkk', form.current, {
        publicKey: 'y_UnfZRCZjjoE3i6t',
      })
      .then(
        () => {
          console.log('SUCCESS!');
          alert("Mail Sent Successfully")
          window.location.reload();
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
    }
    else{
      console.log("Form Error")
    }

  };
  
  
  return (
    <Wrapper id="contact">
      <div className="lightBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Let's get in touch</h1>
            <p className="font15">
              For any queries, Enter the form or react out to us at <span style={{color:'#0b71df'}}><b>office@mithraconsultancy.com</b></span> or call us at <span style={{color:'#0b71df'}}> <b> +91-9666310752</b> </span>
            </p>
          </HeaderInfo>
          <div className="row" style={{ paddingBottom: "30px" }}>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <Form ref={form} onSubmit={sendEmail}>
                <label className="font13">Name:<span style={{color:'red'}}>*</span></label>
                <input type="text" id="fname" name="fname" className="font20 extraBold" />
                <label className="font13">Email:<span style={{color:'red'}}>*</span></label>
                <input type="text" id="email" name="email" className="font20 extraBold" />
                <label className="font13">PhoneNumber(Optional):</label>
                <input type="text" id="ph_no" name="ph_no" maxLength={10} className="font20 extraBold"  />
                <label className="font13">Message:<span style={{color:'red'}}>*</span></label>                
                <textarea rows="4" cols="50" type="text" id="message" name="message" className="font20 extraBold" /> 
                <SumbitWrapper className="flex">         
                              {/* <input type="submit" value="Send" /> */}
               <ButtonInput type="submit" value="Send Message" className="pointer animate radius8" style={{ maxWidth: "220px",fontSize:"large",padding:"5px",height:"45px"}} />

                </SumbitWrapper>
              </Form>
              {/* <SumbitWrapper className="flex"> */}

                {/* <ButtonInput type="submit" value="Send Message" className="pointer animate radius8" style={{ maxWidth: "220px" }} /> */}
              {/* </SumbitWrapper> */}
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 flex" style={{ justifyContent:'center',alignItems:'center'}}>
              {/* <div style={{ flexGrow:'1' }}> */}
                <img src={ContactImg} alt="contact" style={{maxWidth:'100%' }} />
              {/* </div> */}
              {/* <div style={{ width: "50%" }} className="flexNullCenter flexColumn"> */}
                {/* <ContactImgBox>
                  <img src={ContactImg1} alt="office" className="radius6" />
                </ContactImgBox> */}
                {/* <ContactImgBox>
                  <img src={ContactImg2} alt="office" className="radius6" />
                </ContactImgBox> */}
              {/* </div> */}
              {/* <div style={{ width: "50%" }}>
                <div style={{ marginTop: "100px" }}>
                  <img src={ContactImg3} alt="office" className="radius6" />
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  padding: 70px 0 30px 0;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Form = styled.form`
  padding: 70px 0 30px 0;
  input,
  textarea {
    width: 100%;
    // background-color: transparent;
    border: 0px;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #707070;
    height: 30px;
    margin-bottom: 30px;
  }
  textarea {
    min-height: 100px;
  }
  @media (max-width: 860px) {
    padding: 30px 0;
  }
`;
const ButtonInput = styled.input`
  border: 1px solid #7620ff;
  background-color: #7620ff;
  width: 100%;
  padding: 15px;
  outline: none;
  color: #fff;
  :hover {
    background-color: #580cd2;
    border: 1px solid #7620ff;
    color: #fff;
  }
  @media (max-width: 991px) {
    margin: 0 auto;
  }
`;
const ContactImgBox = styled.div`
  max-width: 180px; 
  align-self: flex-end; 
  margin: 10px 30px 10px 0;
`;
const SumbitWrapper = styled.div`
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 50px;
  }
`;









