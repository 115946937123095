import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function ProjectBox({  title, text,icon}) {
  return (
    <Wrapper>
      <Iconbtn style={{color:'#529FF1'}}>
      <FontAwesomeIcon icon = {icon} size="5x" border />
      </Iconbtn>
      <h3 className="font20 extraBold">{title}</h3>
      <p className="font13">{text}</p> 
    </Wrapper>
  );
}
// /rgb(82 159 241)
const Wrapper = styled.div`
  width: 100%;
  margin-top: 30px;
  img {
    width: 100%;
    height: auto;
    margin: 20px 0;
  }
  h3 {
    padding-bottom: 10px;
  }
`;
const ImgBtn = styled.button`
  background-color: transparent;
  border: 0px;
  outline: none;
  padding: 0px;
  margin: 0px;
  :hover > img {
    opacity: 0.5;
  }
`;

const Iconbtn = styled.div`
margin: 15px 15px;
`;